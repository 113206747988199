.education-box-container{
    display: flex;
    justify-content: space-between;
    padding: 0 4%;
}

.education-box{
    width: 30%;
    padding: 2% 2%;
    margin: 0 1%;
    border-radius: 1rem;
    box-shadow: 0px 0px 15px #00000020;
}

.education-logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 7rem;
}

.education-content-year{
    font-size: 1rem;
    text-align: center;
}

.education-content-header{
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    padding-top: 1rem;
    text-align: center;
    line-height: 2rem;
}

.education-content{
    font-size: 1.2rem;
    letter-spacing: 0.03rem;
    padding: 3% 0;
    text-align: center;
    line-height: 2rem;
}

@media screen and (max-width: 800px){
    .education-content-header{
        font-size: 1.1rem;
    }
    .education-content{
        font-size: 1rem;
    }
    .education-box-container{
        flex-direction: column;
    }
    .education-box{
        width: 94%;
        margin: 5% 1%;
    }
}