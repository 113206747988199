.home-container{
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 130px;
}

.home-left{
    text-align: center;
    letter-spacing: 3px;
}

.home-left h1{
    font-weight: 800;
    font-size: 45px;
    color: rgb(27,159,254); 
    transition: 0.4s ease-in-out;
    font-family: "Archivo Black";
    letter-spacing: 5px;
    padding: 10px 0px;
}

.home-left p{
    color: #FFF;
    font-size: 30px;
    transition: 0.6s ease-in-out;
}

.home-left h1:hover{
    font-size: 47px;
}

.home-left p:hover, .home-skills-container span:hover{
    font-size: 32px; 
}

.home-right-profile{
    height: 480px;
    width: auto;
    transition: 1s ease-in-out;
}

.home-right-profile:hover{
    height: 500px;
    width: auto;
}

.left-social-icons-container{
    display: flex;  
    justify-content: space-evenly; 
}

.left-social-icons-container li{
    list-style: none;
}

.left-social-icon{
    height: 30px;
    width: auto;
    transition: 0.4s ease-in-out;
    list-style: none;
}

.left-social-icon:hover{
    height: 33px;
    width: auto;
}

.left-buttons-container{
    padding: 70px 0px; 
    display:flex;
    justify-content: space-around;
}

.left-button{
    font-size: 15px;
    font-weight: 800;
    border-radius: 60px;
    padding: 10px 25px;
    border-color: transparent;
    background-color: #1B9FFE;
    border-style: solid;
    color: rgb(21,23,32);
}

.left-button:hover{
    background-color: transparent;
    color:#1B9FFE;
    border-color: #1B9FFE;
    cursor:pointer;
}

.home-skills-container{
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    letter-spacing: 3px;
}

.home-skills-container span{
    position: relative;
    color: #ffffff;
    font-size: 30px;
    font-family: "Archivo";
    animation: animation 10s ease infinite;
    transition: 0.6s ease-in-out;
}

@keyframes animation{
    0%,100% {top: 0;}
    25% {top: -55px;}
    50% {top: -105px;}
    75% {top: -155px;}
}

@media screen and (max-width: 1050px){
    .home-left h1{
        font-size: 35px;
        padding: 0px;
    }   
    .home-left h1:hover{
        font-size: 37px;
    }
    .home-left p, .home-skills-container span{
        font-size: 25px;
    } 
    .home-left p:hover, .home-skills-container span:hover{
        font-size: 27px;
    }
    .home-right-profile{
        height: 350px;
    }
    .home-right-profile:hover{
        height: 370px;
    }
    .left-buttons-container{
        padding: 50px 0px;
    }
    .left-social-icon{
        height: 25px;
    }
    .left-button{
        font-size: 12px;
    }
}

@media screen and (max-width: 800px){
    .home-container{
        padding-top: 0px;
        padding-bottom: 50px;
    }
    .home-left h1:hover{
        font-size: 35px;
    }
    .home-left p:hover, .home-skills-container span:hover{
        font-size: 25px;
    }
    .home-container{
        flex-direction: column-reverse;
        justify-content: center;
    }
    .home-right-profile{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .home-right-profile:hover{
        height: 350px;
    }
    .left-social-icon{
        height: 25px;
    }
    .left-social-icon:hover{
        height: 25px;
    }
    .left-buttons-container{
        justify-content: space-evenly;
    }
    .left-social-icons-container{
        padding: 0% 10%;
        padding-top: 5%;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 450px){
    .home-left h1{
        font-size: 25px;
        letter-spacing: 3px;
        padding: 5px 0px;
    }
    .home-left h1:hover{
        font-size: 25px;
    }
    .home-left p, .home-skills-container span{
        letter-spacing: 2px;
        font-size: 20px;
    }
    .home-left p:hover, .home-skills-container span:hover{
        font-size: 20px;
    }
    .home-right-profile{
        height: 250px;
    }
    .home-right-profile:hover{
        height: 250px;
    }
    .left-button{
        font-size: 10px;
        padding: 7px 15px;
    }
    .left-social-icons-container{
        padding: 0% 0%;
    }
    .left-social-icon{
        height: 20px;
    }
    .left-social-icon:hover{
        height: 20px;
    }
}