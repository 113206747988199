.skills-box-container{
    display: flex;
    justify-content: space-between;
    padding: 0 4%;
}

.skills-box{
    width: 30%;
    padding: 2% 2%;
    margin: 0 1%;
    border-radius: 1rem;
    box-shadow: 0px 0px 15px #00000020;
}

.skills-header{
    font-size: 1.2rem;
    line-height: 220%;
}

.skills-content{
    font-size: 1.2rem;
    line-height: 220%;
    text-align: justify;
    text-align-last: center;
}

.skills-image-container{
    display: flex;
    justify-content: space-around;
    padding: 5% 0;
}

@media screen and (max-width: 800px){
    .skills-content-header{
        font-size: 1.1rem;
    }
    .skills-content{
        font-size: 1rem;
        padding: 0 5%;
    }
    .skills-box-container{
        flex-direction: column;
    }
    .skills-image-container{
        padding: 3% 10%;
    }
    .skills-box{
        width: 94%;
        margin: 5% 1%;
    }
}

@media screen and (max-width: 800px){
    .skills-image-container{
        padding: 3% 0;
    }
}