body{
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-family: "Archivo";
  }
  
  .navbar-container{
    background-color: rgb(21,23,32);
    margin: 0px;
  }

  .footer-container{
    background-color: rgb(21,23,32);
    margin-top: 10rem;
  }

  .home-container{
    background-color: rgb(21,23,32);
    margin-top: -2px;
  }
  
  ::-webkit-scrollbar {
    width: 0;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  .page-container{
    color: #000000;
    text-align: center;
    justify-content: center;
    padding-top: 3rem;
  }

  .page-header{
    letter-spacing: 0.15rem;
    color: #1B9FFE;
    padding: 2rem 0;
  }

  .puffloader{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 800px){
    .page-container{
      padding-top: 0rem;
    }
  }
  @media screen and (max-width: 400px){
    .page-header{
      font-size: 1.5rem;
    }
  }