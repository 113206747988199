.about-box-container{
    display: flex;
    justify-content: center;
    padding: 2% 2%;
    margin: 0 5%;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0px 0px 15px #00000020;
}

.about-right-profile{
    height: 30rem;
    display: block;
    margin: auto;
    top: 50%;
    border-radius: 0.8rem 0 0 0.8rem;
}

.about-left-header{
    font-size: 1.2rem;
    padding: 2.5rem 0 1.5rem 0;
}

.about-left-content{
    font-size: 1.2rem;
    line-height: 220%;
    padding-left: 8%;
    padding-right: 2%;
    text-align: justify;
    text-align-last: center;
}

@media screen and (max-width: 1050px){
    .about-right-profile{
        height: 25rem;
    }
    .about-left-content{
        line-height: 200%;
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media screen and (max-width: 800px){
    .about-box-container{
        flex-direction: column;
    }
    .about-right-profile{
        height: auto;
        width: 50%;
        border-radius: 0.8rem 0.8rem 0 0;
    }
    .about-left-content{
        line-height: 180%;
        font-size: 1.1em;
    }
    .about-left-header{
        font-size: 1.1rem;
        padding: 1rem 0 0 0;
    } 
}

@media screen and (max-width: 400px){
    .about-right-profile{
        width: 100%;
    }
}