.navbar-container{
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 40px 110px;
}

.navbar-header img{
    height: 70px;
}

#btns{
    display: flex;
    justify-content: center;
    align-items:center;
}

#btns li{
    list-style: none;
    padding: 0px 35px;
    position: relative;
    color:#FFF;
    text-decoration: none;
    font-family: "Archivo";
    font-size: 18px;
    letter-spacing: 1px;
    transition: 0.4s ease-in-out;
}

#btns li:hover{
    color: #1B9FFE;
    cursor: pointer;
}

.navbar-bars{
    display: none;
}

.navbar-bars i{
    color: #ffffff;
}

@media screen and (max-width:1050px){
    #btns{
        display: none;
        flex-direction: column;
        align-items:flex-start;
        position: absolute;
        top: 100px;
        right: -4000px;
        width: 200px;
        height: 290px;
        background: hsla(0, 0%, 0%, 0.90);
        box-shadow: 7px 7px 10px #00000090;
        padding: 40px 0 0 10px;
        transition: 0.2s ease-in-out;
    }
    #btns.active{
        right: 100px;
        display: flex;
    }
    .navbar-bars{
        display: block;
    }
    #btns li{
        margin-bottom: 25px;
    }
    .navbar-bars i{
        color:#ffffff;
        cursor: pointer;
        transition: 0.4s ease-in-out;
    }
    .navbar-bars i:hover{
        color:#1B9FFE;
    }
    .navbar-header img{
        height: 50px;
    }
}

@media screen and (max-width:800px){
    .navbar-container{
        padding: 50px 50px;
    }
    .navbar-name{
        font-size: 20px;
        letter-spacing: 3px;
    }
    .navbar-name:hover{
        transform: none;
        color: #fff;
        font-size: 20px;
    }
    #btns.active{
        right: 50px;
    }
}

@media screen and (max-width:450px){
    .navbar-container{
        padding: 40px 30px;
    }
    .navbar-name{
        font-size: 18px;
    }
    #btns.active{
        right: 30px;
    }
    #btns li{
        font-size: 15px;
        margin-bottom: 20px;
    }
    #btns{
        height: 250px;
        padding: 30px 0 0 10px;
        width: 180px;
    }
}
